<template>
  <v-container class="general">
    <v-layout row wrap>
      <v-flex xs12>
        <v-card>
          <v-layout
            grey
            lighten-5
            row
            wrap
            flex
            xs12
            pt-4
            px-4
            class="mainheader"
          >
            <v-flex xs1>
              <IconSelector v-model="model.icon" />
            </v-flex>
            <v-flex xs11>
              <v-text-field
                type="text"
                v-model="model.name"
                :error-messages="errors.collect('name')"
                v-validate.disable="'required'"
                data-vv-name="name"
                :data-vv-as="$tc('name', 2)"
                :placeholder="$tc('name', 1)"
                background-color="grey lighten-5"
                class="message-name"
                solo
                single-line
                flat
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs12 pb-4 mb-2>
              <v-divider></v-divider>
            </v-flex>
            <v-flex xs6 pl-4 pr-2>
              <p>
                <strong>{{ $t("singular-form") }}:</strong>
              </p>
              <v-text-field
                v-model="model.singular"
                :error-messages="errors.collect('singular')"
                v-validate.disable="'required'"
                data-vv-name="singular"
                :data-vv-as="$tc('singular', 2)"
                :label="$tc('singular', 1)"
                box
              ></v-text-field>
            </v-flex>
            <v-flex xs6 pl-2 pr-4>
              <p>
                <strong>{{ $t("plural-form") }}:</strong>
              </p>
              <v-text-field
                v-model="model.plural"
                :error-messages="errors.collect('plural')"
                v-validate.disable="'required'"
                data-vv-name="plural"
                :data-vv-as="$tc('plural', 2)"
                :label="$tc('plural', 1)"
                box
              ></v-text-field>
            </v-flex>
            <v-flex xs12 px-4 pt-2>
              <p>
                <strong>{{ $t("select-color") }}:</strong>
              </p>
              <v-select
                v-model="model.color"
                :items="colors"
                item-text="name"
                item-value="value"
                :error-messages="errors.collect('color')"
                v-validate.disable="'required'"
                data-vv-name="color"
                :data-vv-as="$tc('color', 2)"
                :label="$tc('color', 1)"
                class="item-color"
                box
              >
                <template slot="selection" slot-scope="data">
                  <font-awesome-icon icon="palette" :color="data.item.value" />
                  <span class="body-2 pl-3">{{ data.item.name }}</span>
                </template>
                <template slot="item" slot-scope="data">
                  <font-awesome-icon icon="palette" :color="data.item.value" />
                  <span class="body-2 pl-3">{{ data.item.name }}</span>
                </template>
              </v-select>
            </v-flex>
            <v-flex xs6 pl-4 py-4>
              <v-btn
                v-if="!depositTypeId"
                @click="createDepositTypes"
                class="blue-button"
                >{{ $t("create") }}</v-btn
              >
              <v-btn
                v-if="depositTypeId"
                @click="updateDepositTypes"
                class="blue-button"
                >{{ $t("save") }}</v-btn
              >
              <v-btn
                :to="{ name: 'deposit_settings' }"
                class="simple-blueborder-button"
                >{{ $t("cancel") }}</v-btn
              >
            </v-flex>
            <v-flex xs6 pr-4 py-4 class="text-xs-right">
              <v-btn
                v-if="depositTypeId"
                @click="deleteDepositTypes"
                class="light-red-button mr-0"
                >{{ $t("delete") }}</v-btn
              >
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    model: {
      icon: "fas tag",
    },
    colors: [
      {
        name: "Light red",
        value: "#EF5350",
      },
      {
        name: "Red",
        value: "#F44336",
      },
      {
        name: "Dark red",
        value: "#B71C1C",
      },
      {
        name: "Light pink",
        value: "#EC407A",
      },
      {
        name: "Pink",
        value: "#E91E63",
      },
      {
        name: "Dark pink",
        value: "#880E4F",
      },
      {
        name: "Light purple",
        value: "#AB47BC",
      },
      {
        name: "Purple",
        value: "#9C27B0",
      },
      {
        name: "Dark purple",
        value: "#4A148C",
      },
      {
        name: "Light blue",
        value: "#42A5F5",
      },
      {
        name: "Blue",
        value: "#2196F3",
      },
      {
        name: "Dark blue",
        value: "#0D47A1",
      },
      {
        name: "Light green",
        value: "#66BB6A",
      },
      {
        name: "Green",
        value: "#4CAF50",
      },
      {
        name: "Dark green",
        value: "#1B5E20",
      },
      {
        name: "Light yellow",
        value: "#FFEE58",
      },
      {
        name: "Yellow",
        value: "#FFEB3B",
      },
      {
        name: "Dark yellow",
        value: "#F57F17",
      },
      {
        name: "Light gray",
        value: "#a9a9a9",
      },
      {
        name: "Black",
        value: "#000000",
      },
    ],
  }),
  computed: {
    groupId() {
      return this.$route.params.group_id;
    },
    groupPluginId() {
      return this.$route.params.group_plugin_id;
    },
    depositTypeId() {
      return this.$route.params.deposit_type_id;
    },
  },
  watch: {
    depositTypeId: {
      immediate: true,
      handler(val) {
        this.showDepositTypes(val);
      },
    },
  },
  methods: {
    toDepositSettings() {
      this.$router
        .push({
          name: "deposit_settings",
          params: {
            group_id: this.groupId,
            group_plugin_id: this.groupPluginId,
          },
        })
        .catch(() => {});
    },
    createDepositTypes() {
      this.$validator.validate().then((result) => {
        if (result) {
          const params = {
            group_id: this.groupId,
            model: {
              group_plugin_id: this.groupPluginId,
              ...this.model,
            },
          };
          this.$store.dispatch("createDepositTypes", params).then((data) => {
            if (data) {
              this.$store.dispatch("addNotification", {
                message: `Deposit type created`,
              });
              this.toDepositSettings();
            }
          });
        }
      });
    },
    showDepositTypes(id) {
      if (!id) return;

      const params = {
        group_id: this.groupId,
        deposit_type_id: id,
        params: {
          group_plugin_id: this.groupPluginId,
        },
      };

      this.$store.dispatch("showDepositTypes", params).then((data) => {
        if (data) {
          this.model = data.data;
        }
      });
    },
    updateDepositTypes() {
      this.$validator.validate().then((result) => {
        if (result) {
          const params = {
            group_id: this.groupId,
            deposit_type_id: this.depositTypeId,
            model: this.model,
          };
          delete params.model.group_plugin_id;
          this.$store.dispatch("updateDepositTypes", params).then((data) => {
            if (data) {
              this.$store.dispatch("addNotification", {
                message: this.$t("deposit-type-updated"),
              });
              this.toDepositSettings();
            }
          });
        }
      });
    },
    deleteDepositTypes() {
      this.$vuetifyConfirmDialog
        .open(
          this.$tc("confirmation", 1),
          this.$t("deposit-type-deletion-warning"),
          this.$t("cancel"),
          this.$t("confirm"),
        )
        .then((state) => {
          if (state) {
            const params = {
              group_id: this.groupId,
              deposit_type_id: this.depositTypeId,
              params: {
                group_plugin_id: this.groupPluginId,
              },
            };
            this.$store.dispatch("deleteDepositTypes", params).then((data) => {
              if (data) {
                this.$store.dispatch("addNotification", {
                  message: this.$t("deposit-type-deleted"),
                });
                this.toDepositSettings();
              }
            });
          }
        });
    },
  },
};
</script>
